import React, { useState, useEffect } from 'react';
import './AttributeList.css';

const AttributeList = ({ attributes, header = true, attachedCreation }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const attributeMapping = {
    baseTheme: 'Genre',
    origin: 'Background',
    skillsAndAbilities: 'Skills',
    additionalDetails: 'Details',
    physicalAppearance: 'Appearance'
  };

  const getAttributeName = (originalName) => {
    return attributeMapping[originalName] || originalName;
  };

  const getHrefForLocation = () => {
    const locationValue = attributes["Within Location (user generated)"];

    // Check if attachedCreation is an array and has at least one element
    if (Array.isArray(attachedCreation) && attachedCreation.length > 0 && attachedCreation[0]?.formValues) {
      const matchingCreation = attachedCreation.find(
        (creation) => creation?.formValues?.["Name"] === locationValue
      );
      return matchingCreation ? `/characters?id=${matchingCreation._id}` : null;
    }

    return null; // Return null if attachedCreation is invalid or doesn't match
  };


  const getImageForLocation = () => {
    const locationValue = attributes["Within Location (user generated)"];
    const matchingCreation = attachedCreation.find(
      (creation) => creation.formValues["Name"] === locationValue
    );
    return matchingCreation ? getBaseURL() + matchingCreation.imageUrl : null;
  };

  function getBaseURL() {
    var url = window.location.href;
    var apiUrl = 'http://localhost:3001/saved/images/';
    if (url.indexOf('charactercomposer.com') > -1)
      apiUrl = 'https://charactercomposer.com/saved/images/';
    return apiUrl;
  }

  useEffect(() => {
    //console.log('attachedCreation');
    //console.log(attachedCreation);
  }, [attachedCreation]);

  return (
    <div className="attribute-list">
      {header && (
        <div className="accordion-header" onClick={handleClick}>
          <h3>Attributes</h3>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
      )}
      {isOpen && (
        <div className="accordion-content">
          <div className="column">
            {Object.entries(attributes)
              .filter(([key]) => key !== 'name' && key !== 'Name' && key !== 'visualTheme')
              .slice(0, Object.entries(attributes).length / 2)
              .map(([key, value]) => (
                <div key={key} className="attribute">
                  <span className="attribute-name">{getAttributeName(key)}:</span>
                  <span className="attribute-value">
                    {key === 'Within Location (user generated)' && getHrefForLocation() ? (
                      <a href={getHrefForLocation()}>
                        {value}
                        {getImageForLocation() && (
                          <img
                            src={getImageForLocation()}
                            alt={value}
                            style={{ maxWidth: '100px', marginLeft: '10px' }}
                          />
                        )}
                      </a>
                    ) : (
                      value
                    )}
                  </span>
                </div>
              ))}
          </div>
          <div className="column">
            {Object.entries(attributes)
              .filter(([key]) => key !== 'name')
              .slice(Object.entries(attributes).length / 2)
              .map(([key, value]) => (
                <div key={key} className="attribute">
                  <span className="attribute-name">{getAttributeName(key)}:</span>
                  <span className="attribute-value">
                    {key === 'Within Location (user generated)' && getHrefForLocation() ? (
                      <a href={getHrefForLocation()} target="_blank">
                        <div className="attributesLinkedCreation ">
                        <div>
                          {value}
                        </div>
                          <div>
                            {getImageForLocation() && (
                            <img
                              src={getImageForLocation()}
                              alt={value}
                            />
                          )}
                        </div>
                        
                        </div>
                      </a>
                    ) : (
                      value
                    )}
                  </span>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AttributeList;
